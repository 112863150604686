<template>
  <div>
    <indications />
  </div>
</template>

<script>
import Indications from '@/components/catalog/Indications/Indications.vue'

export default {
  components: { Indications },
  name: 'Indication',
}
</script>

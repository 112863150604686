<template>
  <v-dialog
    v-model="isActive"
    persistent
    transition="dialog-transition"
    width="325"
    height="270"
  >
    <span class="d-none">{{ change }}</span>

    <v-card class="rounded-lg">
      <v-card-title>
        <v-row>
          <v-col cols="11" class="px-0">
            <h6 class="text-subtitle-1 font-weight-bold text-center">
              <span class="font-work-sans"
                >¿Seguro que desea {{ del ? ' eliminar' : ' reactivar' }}?
              </span>
            </h6>
          </v-col>
          <v-col cols="1" class="pa-0">
            <v-btn color="primary" icon @click="$emit('dismiss')"
              ><v-icon small>fa-times</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="text-center">
        <span class="text-subtitle-1">
          <p class="font-quicksand">
            A continuación se {{ del ? ' eliminará ' : ' reactivará ' }} la
            siguente indicación:
          </p>
        </span>

        <h6 class="text-h6">
          <span class="font-quicksand">
            {{
              indication
                ? `${indication.name} - ${indication.description} `
                : ''
            }}
          </span>
        </h6>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          class="rounded-lg px-4"
          @click="$emit('dismiss')"
        >
          <v-icon small>fa-times</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="rounded-lg elevation-0 px-4"
          @click="activeAlerts"
          :loading="loading"
        >
          <v-icon small>fa-check</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
const IndicationRepository = Repository.get('indications')

export default {
  name: 'IndicationDialog',
  props: {
    indication: { type: Object, default: {} },
    del: { type: Boolean, default: true },
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    loading: false,
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    activeAlerts: async function () {
      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.del
          ? 'Indicación eliminada con éxito'
          : 'Indicación activada con éxito',
      }
      this.loading = true

      try {
        this.del
          ? await IndicationRepository.delete(this.indication.id)
          : await IndicationRepository.reactivate(this.indication.id)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response ? error.response.data.msg : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
